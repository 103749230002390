body {
  margin: 0;
  /* overflow: hidden; */
  background: #000;
}

.message {
  color: #fff;
  font-family: Helvetica;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}
